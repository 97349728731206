/* You can add global styles to this file, and also import other style files */
@import 'houston-ui-theme.scss';

body {
  margin: 0;
}

* {
  font-weight: 500;
  font-family: Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal;
}

mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
}

mat-form-field {
  margin-bottom: 10px;
}

/*
.grid-test-1 > figure.mat-figure {
  justify-content: flex-start !important;
}
*/

.main-panel-style {
  width: 60%;
  margin: 15px auto 0;
}

.success-toastr-style {
  background-color: green;
  font-weight: bold;
}

.error-toastr-style {
  background-color: red;
  font-weight: bold;
  color: white;
}

.loading-shade {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spacer {
  flex: 1 1 auto;
}
